var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "screenHome", attrs: { id: "screenHome" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { staticClass: "map_col", attrs: { span: 16 } },
            [
              _c("el-row", { staticClass: "top_statistics" }, [
                _c(
                  "div",
                  {
                    staticClass: "Dosage_statistics",
                    staticStyle: { color: "#c09b31" },
                  },
                  [
                    _c(
                      "p",
                      [
                        _vm._v("日供水量 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "前一日凌晨到本日凌晨用水量的总和",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#409eff" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("animate-number", {
                      key: _vm.dailyWaterSupply,
                      attrs: {
                        from: "0",
                        to: _vm.dailyWaterSupply,
                        duration: "2000",
                      },
                    }),
                    _c("img", {
                      staticClass: "Dosage_img",
                      attrs: { src: require("@/assets/img/日.png"), alt: "" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "Dosage_statistics",
                    staticStyle: { color: "#1a96d4" },
                  },
                  [
                    _c(
                      "p",
                      [
                        _vm._v("月供水量 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "当月1号凌晨到本日凌晨用水量的总和",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#409eff" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("animate-number", {
                      key: _vm.monthlyWaterSupply,
                      attrs: {
                        from: "0",
                        to: _vm.monthlyWaterSupply,
                        duration: "2000",
                      },
                    }),
                    _c("img", {
                      staticClass: "Dosage_img",
                      attrs: { src: require("@/assets/img/月.png"), alt: "" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "Dosage_statistics",
                    staticStyle: { color: "#024383" },
                  },
                  [
                    _c(
                      "p",
                      [
                        _vm._v("年供水量 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "当年1月1号凌晨到本日凌晨用水量的总和",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#409eff" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("animate-number", {
                      key: _vm.yearWaterSupply,
                      attrs: {
                        from: "0",
                        to: _vm.yearWaterSupply,
                        duration: "2000",
                      },
                    }),
                    _c("img", {
                      staticClass: "Dosage_img",
                      attrs: { src: require("@/assets/img/年.png"), alt: "" },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                {
                  staticClass: "bottom_map",
                  staticStyle: { "margin-top": "10px" },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", {
                      key: _vm.mapKey,
                      staticClass: "app-container flex-item",
                      style: { height: _vm.chartHeight * 2 - 85 + "px" },
                      attrs: { id: "txdituContainer" },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "device_layer" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      key: _vm.checkKey,
                      on: { change: _vm.chooseCheckbox },
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    [
                      _vm._l(_vm.deviceTypeList, function (item, index) {
                        return [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: item.dtValue,
                                disabled: item.disabled,
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "echart_col", attrs: { span: 8 } },
            [
              _c("el-row", [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "left",
                      margin: "0",
                      color: "#787a7d",
                      "font-size": "16px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("分站每日水量统计")]
                ),
                _c("div", {
                  style: { width: "100%", height: _vm.chartHeight - 20 + "px" },
                  attrs: { id: "dailyChart" },
                }),
              ]),
              _c("el-row", { staticStyle: { "margin-top": "10px" } }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "left",
                      margin: "0",
                      color: "#787a7d",
                      "font-size": "16px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("分站每月水量统计")]
                ),
                _c("div", {
                  style: { width: "100%", height: _vm.chartHeight - 20 + "px" },
                  attrs: { id: "monthlyChart" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
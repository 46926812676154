<template>
  <div class="screenHome" id="screenHome">
    <el-row :gutter="16" >
      <el-col :span="16" class="map_col">
        <el-row  class="top_statistics">
          <div class="Dosage_statistics" style="color: #c09b31;">
            <p>日供水量
              <el-tooltip class="item" effect="dark" content="前一日凌晨到本日凌晨用水量的总和" placement="bottom-start">
                <i class="el-icon-question" style="color:#409eff"></i>
              </el-tooltip>
            </p>
            <animate-number from="0" :to="dailyWaterSupply" :key="dailyWaterSupply" duration="2000"></animate-number>
            <img src="@/assets/img/日.png" alt="" class="Dosage_img">
          </div>
          <div class="Dosage_statistics" style="color: #1a96d4;">
            <p>月供水量
              <el-tooltip class="item" effect="dark" content="当月1号凌晨到本日凌晨用水量的总和" placement="bottom-start">
                <i class="el-icon-question" style="color:#409eff"></i>
              </el-tooltip>
            </p>
            <animate-number from="0" :to="monthlyWaterSupply" :key="monthlyWaterSupply" duration="2000"></animate-number>
            <img src="@/assets/img/月.png" alt="" class="Dosage_img">
          </div>
          <div class="Dosage_statistics" style="color: #024383;">
            <p>年供水量
              <el-tooltip class="item" effect="dark" content="当年1月1号凌晨到本日凌晨用水量的总和" placement="bottom-start">
                <i class="el-icon-question" style="color:#409eff"></i>
              </el-tooltip>
            </p>
            <animate-number from="0" :to="yearWaterSupply" :key="yearWaterSupply" duration="2000"></animate-number>
            <img src="@/assets/img/年.png" alt="" class="Dosage_img">
          </div>
        </el-row>
        
        <el-row class="bottom_map" style="margin-top: 10px;">
          <el-col :span="24" class="" >
            <div class="app-container flex-item" id="txdituContainer" :style="{ height: (chartHeight * 2 - 85) + 'px',}" :key="mapKey"> </div>
          </el-col>
        </el-row>
        <!-- 多选层 -->
        <div class="device_layer">
          <el-checkbox-group v-model="checkList" @change="chooseCheckbox" :key="checkKey">
            <template v-for="(item ,index) in deviceTypeList">
              <el-checkbox  :label="item.dtValue" :disabled="item.disabled" >{{ item.name }}</el-checkbox>
            </template>
            <!-- <el-checkbox label="测控一体化闸门"></el-checkbox>
            <el-checkbox label="箱式超声波明渠流量计"></el-checkbox>
            <el-checkbox label="开放式超声波明渠流量计"></el-checkbox>
            <el-checkbox label="无线式超声波明渠流量计"></el-checkbox>
            <el-checkbox label="巴歇尔槽明渠流量计"></el-checkbox>
            <el-checkbox label="水位流量计"></el-checkbox>
            <el-checkbox label="水位计"></el-checkbox> -->
          </el-checkbox-group>
        </div>
      </el-col>
      <el-col :span="8" class="echart_col">
        <el-row>
          <p style="text-align:left;margin:0;color: #787a7d;font-size: 16px;font-weight: 600;">分站每日水量统计</p>
          <div  id="dailyChart" :style="{width: '100%', height: chartHeight-20 + 'px',}"></div>
        </el-row>
        <el-row style="margin-top: 10px;">
          <p style="text-align:left;margin:0;color: #787a7d;font-size: 16px;font-weight: 600;">分站每月水量统计</p>
          <div id="monthlyChart" :style="{width: '100%', height: chartHeight-20 + 'px',}"></div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as echarts from 'echarts';
  
  export default {
    name: 'dashboard-admin',
    components: {    
      
    },
    data() {
      return {
        checkKey:"checkKey",
        mapKey:"mapKey",
        dailyWaterSupply:0,//日供水量
        monthlyWaterSupply:0,//月供水量
        yearWaterSupply:0,//年供水量
        checkList:[//多选框组
          "HS_XSCSBMQLLJ",
          "HS_KFSCSBMQLLJ",
          "HS_WXSCSBMQLLJ",
          "HS_BXECMQLLJ",
          "HS_SWLLJ",
          "HS_CKYTHZM",
          "HS_SWJ"
        ],
        dailyChart:'',//顶部折现图分站每日水量统计-用于屏幕放大缩小重新渲染
        monthlyChart:'',//顶部折现图分站月度水量统计-用于屏幕放大缩小重新渲染
        // chartHeight:200,//echart高度
        isTotalMeter:[],
        tipDatas:[],
      }
    },
    created() {
      
    },
    computed:{
      chartHeight() {
        let ele = document.getElementById("screenHome");
        if(ele){
          var allHeight = ele.offsetHeight - 60 //减去顶部标题+padding，减去中部标题+padding，减去中部日期行
          return Math.ceil(allHeight/2)
        }else{
          return 400
        }
        
      },
      deviceTypeList(){
        let newList = JSON.parse(JSON.stringify(this.$store.state.deviceType))
        if(newList&&newList.length>0){
          newList.forEach((item)=>{
            item.disabled =false
          })
        }
        return   newList
      },
    },
    mounted() {
      basicDataApi.GetDay_Month_Year_WaterUsed_Statistics().then((res)=>{
        if(res.code == 200){
          this.dailyWaterSupply = res.result.Day?res.result.Day.sum_used:0
          this.monthlyWaterSupply = res.result.Month? res.result.Month.sum_used:0
          this.yearWaterSupply = res.result.Year? res.result.Month.sum_used:0
        }
      })
      // 分站每日水量统计
      basicDataApi.GetEveryDay_WaterUsed_StatisticsForArea().then((res)=>{
        if(res.code == 200){ 
          var xDatas=[]
          if(res.result){
            res.result.x.forEach((item)=>{
              xDatas.push(item?this.$basicFun.dataFormat(new Date(item),'yyyy/MM/dd hh:mm'):"")
            })
          }
            
          // var xDatas = res.result?res.result.x:[]
          // console.log(xDatas,111111)
          // xDatas.forEach((item)=>{
          //   item = JSON.parse(JSON.stringify(item.substring(0,13)))
          //   console.log(item)
          // })
          var series =[]
          var tits=[]
          if(res.result&&res.result.y.length>0){
            res.result.y.forEach((item)=>{
               let ss ={
                name:item.AreaName,
                type: 'line',
                data: item.Values,
                smooth: true
              }
              tits.push(item.AreaName)
              series.push(ss)
            })
          }
          this.dailyChart = echarts.init(document.getElementById('dailyChart'));
            this.dailyChart.setOption({
              // title: {
              //   text: '分站每日水量统计'
              // },
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                top:'20%',
                bottom:'15%',
                right:'10%',
                left: '13%',  // 增加左边的内边距
              },
              legend: {
                data: tits,
                x:'right',      //可设定图例在左、右、居中
                y:'top',     //可设定图例在上、下、居中
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: true,//坐标轴两边留白
                  axisTick: {show: false},
                  data: xDatas,
                  axisLabel: {
                    rotate: -5 // 设置为负值表示逆时针旋转
                  },
                  interval: 0, // 0 表示强制显示所有标签，'auto' 表示自动间隔
                  axisLabel: {
                    interval: 'auto', // 可选，自动隐藏重叠的标签
                  },
                },
                
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: series
            })

          }
        })
        // 分站每月水量统计
        basicDataApi.GetEveryMonth_WaterUsed_StatisticsForArea().then((res)=>{
        if(res.code == 200){ 
          // var xDatas =res.result?res.result.x:[]
          var xDatas=[]
          if(res.result){
            res.result.x.forEach((item)=>{
              xDatas.push(item?this.$basicFun.dataFormat(new Date(item),'yyyy/MM/dd'):"")
            })
          }
          var series =[]
          var tits=[]
          if( res.result&&res.result.y.length>0){
            res.result.y.forEach((item)=>{
               let ss ={
                name:item.AreaName,
                type: 'line',
                data:item.Values,
                smooth: true
              }
              tits.push(item.AreaName)
              series.push(ss)
            })
          }
          this.monthlyChart = echarts.init(document.getElementById('monthlyChart'));
            this.monthlyChart.setOption({
              // title: {
              //   text: '分站每月水量统计'
              // },
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                top:'20%',
                bottom:'15%',
                right:'10%',
                left: '15%',  // 增加左边的内边距
              },
              legend: {
                data: tits,
                x:'right',      //可设定图例在左、右、居中
                y:'top',     //可设定图例在上、下、居中
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,//坐标轴两边留白
                  axisTick: {show: false},
                  data: xDatas,
                  axisLabel: {
                    rotate: -5 // 设置为负值表示逆时针旋转
                  },
                  interval: 0, // 0 表示强制显示所有标签，'auto' 表示自动间隔
                  axisLabel: {
                    interval: 'auto', // 可选，自动隐藏重叠的标签
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: series
            })

          }
        })
        this.tipDatas=[]
        basicDataApi.dtzsGetList().then(response => {
          if(response.code == 200){
            this.centerLatitude = response.result.latitude
            this.centerLongitude = response.result.longitude
            this.tipDatas=[]
            basicDataApi.jczlSbglSearch({ page: 1,limit: 999999,deviceTypeList:[
              "HS_XSCSBMQLLJ",
              "HS_KFSCSBMQLLJ",
              "HS_WXSCSBMQLLJ",
              "HS_BXECMQLLJ",
              "HS_SWLLJ",
              "HS_CKYTHZM",
              "HS_SWJ"
              ]}).then(res => {
              if(res.code == 200){
                    if(res.data&&res.data.length>0){
                      res.data.forEach((item)=>{
                        let ss ={
                          id: item.id,//必须,标点唯一id
                          content: item.installedLocation,//标点名称
                          latitude: item.latitude,//标点坐标 - 纬度
                          longitude:item.longitude,//标点坐标 - 经度
                          tipsContent:item.installedLocation,//标点悬浮窗标题
                          data:item
                        }
                        this.tipDatas.push(ss)
                      }) 
                      this.mapKey =new Date().getTime()
                    }
                  this.$nextTick(()=>{
                      this.initMap()//初始化地图
                  })
              }else{
                this.$notify({
                  message: response.message,
                  type: 'warning',
                  duration: 2000
                });
              }
            }).catch(()=>{
            })
          }
        }).catch(()=>{})
      // echart屏幕放大缩小重新渲染
      window.onresize = () => {
        this.dailyChart.resize();
        this.monthlyChart.resize();
        this.$forceUpdate()
      };
    },
    methods: {
      //选择设备切换地图标点信息
      chooseCheckbox(e){
        this.deviceTypeList.forEach((item)=>{
          item.disabled =true
        })
        this.tipDatas=[]
        basicDataApi.dtzsGetList().then(response => {
          if(response.code == 200){
            this.centerLatitude = response.result.latitude
            this.centerLongitude = response.result.longitude
            this.tipDatas=[]
            basicDataApi.jczlSbglSearch({ page: 1,limit: 999999,deviceTypeList:e}).then(res => {
            if(res.code == 200){
                  if(res.data&&res.data.length>0){
                    res.data.forEach((item)=>{
                      let ss ={
                        id: item.id,//必须,标点唯一id
                        content: item.installedLocation,//标点名称
                        latitude: item.latitude,//标点坐标 - 纬度
                        longitude:item.longitude,//标点坐标 - 经度
                        tipsContent:item.installedLocation,//标点悬浮窗标题
                        data:item
                      }
                      this.tipDatas.push(ss)
                    }) 
                  }
                  this.mapKey =new Date().getTime()
                this.$nextTick(()=>{
                  this.initMap()//初始化地图
                })
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          }).catch(()=>{
            this.deviceTypeList.forEach((item)=>{
              item.disabled =false
            })
          })
          }
        }).catch(()=>{
          this.deviceTypeList.forEach((item)=>{
            item.disabled =false
          })
        })
   
      },
      //初始化地图
      initMap(){
        var flag =this
        setTimeout(()=>{
          flag.deviceTypeList.forEach((item)=>{
            item.disabled  = false
          })
          flag.checkKey =new Date().getTime()
        },1000)
        var map = new window.BMapGL.Map('txdituContainer');
        if(this.tipDatas.length<=0){
          var point = new window.BMapGL.Point(Number(115.7), Number(39.4));
          map.centerAndZoom(point, 10);
        }else{
          this.tipDatas.forEach((item)=>{
          var point = new window.BMapGL.Point(Number(item.longitude), Number(item.latitude));
          map.centerAndZoom(point, 10);
          var marker = new window.BMapGL.Marker(point);
          map.addOverlay(marker)
           var opts = {
            width: 0,
            height: 0,
            title: item.data.deviceNo
          };
            var allUrl = window.location.href
          const index = allUrl.lastIndexOf("/#/")
          const url = allUrl.substring(0, index+3) + 'hsycjkequipmentmanagement/details'
          var infoWindow=""
          if(item.data.deviceTypeId =='HS_CKYTHZM'){
            // <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">设备编号：${item.data.deviceNo}</div>
            infoWindow =new window.BMapGL.InfoWindow( `<div style="width:100%;color:#000;text-align:left;height:auto;">
              <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(item.data))}" style="color:#409EFF">安装位置：${item.data.installedLocation} <a/></p>
              <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${item.data.deviceTypeName}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;">当前闸位：${item.data.gatePosition}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">瞬时流量：${item.data.flowRate}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;">累计水量：${item.data.totalFlow}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">流量计水位：${item.data.waterLevel_LLJ}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;">流量计水深：${item.data.waterDepth}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">水位计水位：${item.data.waterLevel_SWJ}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">流速：${item.data.flowVelocity}</div>
              <div style="width:100%;color:#b4b4b4;font-size:14px;"></div>
              </div>`, opts);
          }else if(item.data.deviceTypeId =='HS_SWJ'){
            infoWindow =new window.BMapGL.InfoWindow( `<div style="width:100%;color:#000;text-align:left;height:auto;">
                <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(item.data))}" style="color:#409EFF">安装位置：${item.data.installedLocation} <a/></p>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${item.data.deviceTypeName}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">水位：${item.data.waterLevel_SWJ}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;"></div>
              </div>`, opts);
          }else{
            infoWindow = new window.BMapGL.InfoWindow( `<div style="width:100% 10px;color:#000;text-align:left;height:auto;">
                <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(item.data))}" style="color:#409EFF">安装位置：${item.data.installedLocation} <a/></p>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${item.data.deviceTypeName}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">瞬时流量：${item.data.flowRate}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">累计水量：${item.data.totalFlow}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;margin-right:10px">水位：${item.data.waterLevel_LLJ}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;">水深：${item.data.waterDepth}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;">流速：${item.data.flowVelocity}</div>
                <div style="width:100%;color:#b4b4b4;font-size:14px;"></div>
              </div>`, opts);
          } 

          marker.addEventListener('click', function () {
              map.openInfoWindow(infoWindow, point); // 开启信息窗口
            });
          })
        }
        var zoomControl = new window.BMapGL.ZoomControl();
        map.addControl(zoomControl);

        // 启用滚轮缩放
        map.enableScrollWheelZoom(true);

        // console.log(this.tipDatas,789999)
        map.setMapType(BMAP_SATELLITE_MAP);      // 设置地图类型为普通卫星地图


        
      
      // for (let i = 0; i < this.tipDatas.length; i++) {
      //   this.tipDatas[i].styleId = 'myStyle';//标点使用的样式
      //   this.tipDatas[i].position = new TMap.LatLng(this.tipDatas[i].latitude,this.tipDatas[i].longitude);//标点坐标
      //   this.tipDatas[i].content = this.tipDatas[i].content;//标点名称
      //   this.tipDatas[i].properties = {
      //     title:this.tipDatas[i].tipsContent,// 标点悬浮窗标题
      //   }
      // }

      // var center = new TMap.LatLng(this.centerLatitude,this.centerLongitude);//设置中心点坐标
      // //初始化地图
      // var map = new TMap.Map(txdituContainer, {
      //   center: center
      // });
      
      // //创建点聚合对象
      // var markerCluster = new TMap.MarkerCluster({
      //     id: 'cluster', //图层id
      //     map: map,       //设置点聚合显示在哪个map对象中（创建map的段落省略）
      //     enableDefaultStyle: true,   //使用默认样式
      //     minimumClusterSize: 2,  //最小聚合点数：2个
      //     geometries: this.tipDatas,
      //     zoomOnClick: true,  //点击聚合数字放大展开
      //     gridSize: 60,       //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
      //     averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
      //     maxZoom: 16 //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
      // });
      // // //初始化infoWindow
      // var infoWindow = new TMap.InfoWindow({
      //   map: map,
      //   position: new TMap.LatLng(26.223556, 107.520795),
      //   offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      // });
      // infoWindow.close();//初始关闭信息窗关闭

      // // url处理
      // // var allUrl = window.location.href
      // // const index = allUrl.lastIndexOf("/#/")
      // // const url = allUrl.substring(0, index+3) + 'hsycjkequipmentmanagement/details'
      // // 跳转抄表信息页面

      // //监听标注点击事件 - 带跳转
      // markerCluster.on("click", function (evt) {
      //   //设置infoWindow
      //   // console.log(evt,777777)
      //   var allUrl = window.location.href
      //   const index = allUrl.lastIndexOf("/#/")
      //   const url = allUrl.substring(0, index+3) + 'hsycjkequipmentmanagement/details'
      //   infoWindow.open(); //打开信息窗
      //   infoWindow.setPosition(evt.cluster.geometries[0].position);//设置信息窗位置

      //   // <a href="${url}?id=${evt.cluster.geometries[0].id}" style="color:#409EFF">"安装位置：${evt.cluster.geometries[0].data.installedLocation} <a/>
        
      //   if(evt.cluster.geometries[0].data.deviceTypeId =='HS_CKYTHZM'){
      //     infoWindow.setContent(`<div style="width:100%;margin:0 10px;color:#000;text-align:left; id="abc"">
      //     <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(evt.cluster.geometries[0].data))}" style="color:#409EFF">安装位置：${evt.cluster.geometries[0].data.installedLocation} <a/></p>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备编号：${evt.cluster.geometries[0].data.deviceNo}</div>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${evt.cluster.geometries[0].data.deviceTypeName}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">流量计水位：${evt.cluster.geometries[0].data.waterLevel_LLJ}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;">流量计水深：${evt.cluster.geometries[0].data.waterDepth}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">水位计水位：${evt.cluster.geometries[0].data.waterLevel_SWJ}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;">累计水量：${evt.cluster.geometries[0].data.totalFlow}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">流速：${evt.cluster.geometries[0].data.flowVelocity}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;">当前闸位：${evt.cluster.geometries[0].data.gatePosition}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">瞬时流量：${evt.cluster.geometries[0].data.flowRate}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;"></div>
      //     </div>`);//设置信息窗内容

      //   }else if(evt.cluster.geometries[0].data.deviceTypeId =='HS_SWJ'){
      //     infoWindow.setContent(`<div style="width:100%;margin:0 10px;color:#000;text-align:left;">
      //       <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(evt.cluster.geometries[0].data))}" style="color:#409EFF">安装位置：${evt.cluster.geometries[0].data.installedLocation} <a/></p>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备编号：${evt.cluster.geometries[0].data.deviceNo}</div>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${evt.cluster.geometries[0].data.deviceTypeName}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">水位：${evt.cluster.geometries[0].data.waterLevel_SWJ}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;"></div>
      //     </div>`);//设置信息窗内容

      //   }else{
      //     infoWindow.setContent(`<div style="width:100%;margin:0 10px;color:#000;text-align:left;">
      //       <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;"><a href="${url}?row=${encodeURIComponent(JSON.stringify(evt.cluster.geometries[0].data))}" style="color:#409EFF">安装位置：${evt.cluster.geometries[0].data.installedLocation} <a/></p>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备编号：${evt.cluster.geometries[0].data.deviceNo}</div>
      //     <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">设备类型：${evt.cluster.geometries[0].data.deviceTypeName}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">水位：${evt.cluster.geometries[0].data.waterLevel_LLJ}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;">水深：${evt.cluster.geometries[0].data.waterDepth}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">累计水量：${evt.cluster.geometries[0].data.totalFlow}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;">流速：${evt.cluster.geometries[0].data.flowVelocity}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;margin-right:10px">瞬时流量：${evt.cluster.geometries[0].data.flowRate}</div>
      //       <div style="width:50%;color:#b4b4b4;font-size:14px;"></div>
      //     </div>`);//设置信息窗内容
      //   }
      // })

      },
    },
    // 销毁定时器
    beforeDestroy() {
      
    },
    
   
  }

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
 .screenHome{
   min-height: calc(100vh - 45px - 25px);
   background-color: #FFF;
   .map_col{
    position: relative;
    .device_layer{
      z-index: 9999;
      position: absolute;
      // top: 110px;
      // left: 18px;
      right: 10px;
      bottom: 0px;
      width: 200px;
      height: 180px;
      background: #eef6fc;
      ::v-deep .el-checkbox{
        margin: 2px 0 0 10px;
        display: block;
      }
    }
   }
   .echart_col{
    padding : 10px 10px 0 0;
   }
   .Dosage_statistics{
    position: relative;
    width: 30.33%;
    float: left;
    margin: 0.5% 1.5% 0 1.5%;
    border-radius: 10px;
    // background: linear-gradient(to right, #1a96d4, #f3f8fd); 
    background: #eef6fc; 
    // text-align: center;
    font-size: 42px;
    font-weight: bold;
    padding: 5px 0 0 4%;
    .Dosage_img{
      position: absolute;
      right: 10%;
      top: 0px;
      box-shadow: 0 0 10px rgba(26, 150, 212, 0.5); /* 这将添加阴影 */ 
      border-radius: 50%; /* 这将使元素变为圆形 */  
    }
    p{
      font-size: 18px;
      margin: 0px;
      font-weight: 100;
    }
    .item{
      font-size: 14px;
    }
   }
   .bottom_map{
    
    
    
   }
   #txdituContainer {
    background-color: #FFF;
    overflow: hidden;
    // width: 100%;
    height: calc(100vh - 45px - 35px - 20px - 110px);
    margin: 0;
    font-family: "微软雅黑";
    
  }
  //隐藏百度地图版权信息
  ::v-deep .BMap_cpyCtrl,::v-deep  .anchorBL {
    display: none;
   }

 }
</style>
